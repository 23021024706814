import React from "react";

export default function ShowInvoice({ data }) {
  const invoice = data.invoice_id;

  return (
    <div className="table-responsive">
      <table className="table table-light">
        <thead>
          <tr>
            <th>Product</th>
            <th>type</th>
          </tr>
        </thead>
        <tbody>
          {invoice &&
            invoice.product &&
            invoice.product.map((product, index) => (
              <tr key={index}>
                <td>{product.title}</td>
                <td>{product.type}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
