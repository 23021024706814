import React, { useContext, useState } from "react";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { widgetLocalization } from "./../../Localization/widget";
import ReactPaginate from "react-paginate";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { event } from "jquery";
import ShowInvoice from "./ShowInvoice";

export default function PaginateItems({ itemsPerPage, dataSet }) {
  const { lang } = useContext(LangauageContext);
  const [show, setShow] = useState(false);
  const [showItem, setShowItem] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    setShow(true);
    const elementData = event.target.dataset.invoice;
    const item = dataSet.filter((element)=> element.id == elementData);
    setShowItem(item[0]);
  };

  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = dataSet.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataSet.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % dataSet.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Fee</th>
            <th scope="col">Status</th>
            <th scope="col">Date</th>
            <th scope="col">invoice</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.type}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.statue}</td>
                <td>{transaction.created_at}</td>
                <td>
                  {transaction.type === "withdarw" ? null : (
                    <Button data-invoice={transaction.id} variant="primary" onClick={handleShow}>
                      show Invoice
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invoice Detials</Modal.Title>
        </Modal.Header>
        <Modal.Body><ShowInvoice data={showItem} /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactPaginate
        breakLabel="..."
        nextLabel={widgetLocalization[lang].next + " >"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        previousLabel={"< " + widgetLocalization[lang].previous}
        renderOnZeroPageCount={null}
        activeClassName={"active"}
      />
    </>
  );
}
